import React, { useState,useRef, useEffect } from 'react';
import { useCompose } from '@context/compose';
import { useView } from '@context/view';
import { useLang } from '@context/lang';
import CancelDialog from '@components/Compose/CancelDialog';
import SendButton from './SendButton';
import Field from './Fields/Field';
import FieldErrors from './Fields/FieldErrors';

import './Compose.scss';
import { useFieldErrors } from '../../../context/fieldErrors';
import { useMessageDraft } from '../../../context/messageDraft';
import { useMessages } from '../../../context/messages';
import { useAnalytics } from '../../../context/analytics';
import { useConfig } from '../../../context/config';

function Compose() {
	const fieldRef = useRef([]);
    const errorListToggleRef = useRef(null);
    const editorRef = useRef();
    const { setThread, setFolder, setManager } = useView();
    const { allowDrafts } = useConfig();
    const { status, formData } = useCompose();
    const { messageContext, sendNewMessage, setMessageContext } = useMessageDraft();
    const { setTopicId, setSubject, setMessageBody, setCustomFields } = useMessageDraft();
    const { setMessages } = useMessages() 
    const { fieldErrors, setFieldErrors } = useFieldErrors();
    const { dict } = useLang();
    const { viewStart, viewEnd, publish } = useAnalytics();
    const [isCanceling, setIsCanceling] = useState(false);
    
    useEffect(() => {
        viewStart('compose');
        publish({resource: 'compose'});
        viewEnd('compose');
    }, [])

    useEffect(() => {
        if (fieldRef && messageContext && messageContext?.draft) {
            const attributes = JSON.parse(messageContext.attributes);
            setTopicId(attributes.topic_id);
            setSubject(messageContext.subject);
            setMessageBody(messageContext.body);
            setCustomFields(attributes.compose_data);
            fieldRef.current.forEach(ref => {
                //console.log(ref);
                if (ref.field.name === 'subject') {
                    ref.setFieldValue(messageContext.subject);
                } else if (ref.field.name === 'body') {
                    ref.setFieldValue(messageContext.body);
                } else if (ref.field.name === 'attachments') {
                    // do nothing
                    ref.setFieldValue(messageContext.attachments);
                } else {
                    const data = attributes.compose_data[ref.field.name];
                    if (data) {
                        ref.setFieldValue(data.value);
                    } else {
                        // do nothing
                        //console.error('not found ', ref.field.name);
                    }
                }
            });
        }
    }, [fieldRef, messageContext])

    const cancelDialogCancelHandler = () => {
        publish({resource: 'compose', type: 'modal stay'});
        setIsCanceling(false);
    }

    const cancelDialogContinueHandler = () => {
        publish({resource: 'compose', type: 'modal leave'});
        setFolder('inbox');
        setThread(null);
        setMessages(null);
        setTopicId('');
        setSubject('');
        setMessageBody(null);
        setCustomFields([]);
        setMessageContext({});
        fieldRef.current.forEach(ref => {
            //console.log(ref);
            if (ref.field.name === 'subject') {
                ref.setFieldValue('');
            } else if (ref.field.name === 'body') {
                ref.setFieldValue('');
            } else if (ref.field.name === 'attachments') {
                // do nothing
            } else {
                ref.setFieldValue('');
            }
        });
        setManager('browse');
    }

    const onCancelHandler = () => {
        setIsCanceling(true);
    }

    const onSubmitHandler = (draft = false) => {
        if (!draft) {
            const fieldErrs = getFieldErrors(formData.fields);
            if (fieldErrs.length > 0) {
                setFieldErrors(fieldErrs);
                return;
            }
        }
        sendNewMessage(draft);
    }

    const getFieldErrors = (flds) => {
        let fieldErrs = [];
        flds.forEach((field, idx) => {
            const ref = fieldRef.current[idx];
            const invalidFields = ref.validateField(field);
            if (invalidFields.length > 0) {
                invalidFields.forEach(invalidField => {
                    if (!invalidField.ref) {
                        invalidField.ref = ref;
                    }
                })
                fieldErrs = fieldErrs.concat(invalidFields);
            }
        })
        return fieldErrs;
    }

    const sendEditorBody = (body) => {
        editorRef.current = body;
    }
    return (
        <div>
            <section className='oms--compose-section'>
                {isCanceling && <CancelDialog onCancel={cancelDialogCancelHandler} onContinue={cancelDialogContinueHandler} />}
                <div>
                    <form onSubmit={e => e.preventDefault()} name='message' className='oms--compose-panel'>
                        <div className='oms--body'>
                            {status === 'loaded' &&
                            <>
                                {formData.banner && <div className='oms--body-title'>{formData.banner}</div>}								
                                <div
                                    className='oms--body-title'
                                    dangerouslySetInnerHTML={{
                                    __html: dict.asteriskMessage.label
                                    }}
                                ></div>
                                {fieldErrors.length > 0 && <FieldErrors errorListToggleRef={errorListToggleRef} />}
                                {formData.fields.map((field, idx) => (<Field
                                    key={idx}
                                    index={idx + '-' + field.name}
                                    field={field}
                                    ref={(element) => { fieldRef.current[idx] = element }}
                                    sendEditorBody={sendEditorBody}
                                />
                                ))}
                            </>
                            }
                        </div>
                        <div className='oms--div-hr'></div>
                        <div className='oms--footer'>
                            <SendButton
                                id='send-button'
                                className='oms--primary'
                                onClick={() => onSubmitHandler(false)}
                                tabIndex='0'
                                aria-label={dict.sendLabel.ally}
                            />
                            {allowDrafts &&
                                <button
                                    id='drafts-button'
                                    className='oms--secondary'
                                    onClick={() => {onSubmitHandler(true)}}
                                    tabIndex='0'
                                    aria-label={dict.saveLabel.ally}
                                >{dict.saveLabel.label}</button>
                            }
                            <button
                                id='cancel-button'
                                emphasis='low'
                                className='oms--secondary'
                                onClick={onCancelHandler}
                                tabIndex='0'
                                aria-label={dict.cancelLabel.ally}
                            >
                                {dict.cancelLabel.label}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Compose;
