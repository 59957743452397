import React, { useEffect, useState } from 'react';

const ConfigContext = React.createContext();

let match;

function ConfigProvider({configMap, children}) {
    const [token, setToken] = useState(configMap.token);
    const [brand, setBrand] = useState(configMap.brand);
    const [language, setLanguage] = useState(configMap.language);
    const [viewManager, setViewManager] = useState(configMap.viewManager);
    const [portal, setPortal] = useState(configMap.portal);
    const [allowCompose, setAllowCompose] = useState(configMap.allowCompose);
    const [allowReply, setAllowReply] = useState(configMap.allowReply);
    const [hideFilter, setHideFilter] = useState(configMap.hideFilter);
    const [allowDrafts, setAllowDrafts] = useState(configMap.allowDrafts);
    const [isMobile, setIsMobile] = useState(false);
    const [messageMetadata, setMessageMetadata] = useState(configMap.messageMetadata);
    const [topicId, setTopicId] = useState(configMap.topicId);

    const handleMediaChange = (match) => {
        setIsMobile(match.matches);
    }

    useEffect(() => {
        if (window.matchMedia) {
            match = window.matchMedia('(max-width:768px)')
            handleMediaChange(match)
            match.addListener(handleMediaChange);
        }
        return (() => {
            match.removeListener(handleMediaChange)
        })
    }, [])

    const provider = {
        token, setToken,
        brand, setBrand,
        language, setLanguage,
        viewManager, setViewManager,
        portal, setPortal,
        allowCompose, setAllowCompose,
        allowReply, setAllowReply,
        hideFilter, setHideFilter,
        allowDrafts, setAllowDrafts,
        isMobile, setIsMobile,
        messageMetadata, setMessageMetadata,
        topicId, setTopicId
    }

    return <ConfigContext.Provider value={provider}>{children}</ConfigContext.Provider>
}

function useConfig() {
    const context = React.useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigContext provider');
    }
    return context;
}

export { ConfigProvider, useConfig }