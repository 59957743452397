import React, { useState ,forwardRef ,useImperativeHandle,useRef, useEffect } from 'react';
import FieldNotification from './FieldNotification';
import ScreenReaderOnly from '../../ScreenReaderOnly';
import FieldTip from './FieldTip';
import Input from './Input';
//import Phone from './Phone';
import Select from './Select';
import Attachment from './Attachment';
import DateField from './DateField';
import Radio from './Radio';
import './Fields.scss';
import { useMessageDraft } from '../../../../context/messageDraft';
import OptumEditor from './OptumEditor';

const yearPattern = '(19[0-9][0-9]|20[0-9][0-9])'
const dayPattern = '(0[1-9]|1[0-9]|2[0-9]|3[0-1])'
const monthPattern = '(0[1-9]|1[0-2])'
const numbersOnly = '[^\\d]';
const numbersAndDashes = '[^\\d-]';
const sep = '(/)'
const numbersAndSlashes = '[^\\d/]'

const reg = new RegExp(
    '^' + monthPattern + sep + dayPattern + sep + yearPattern + '$',
    'g'
)
    
const areaCodePattern = '([0-9][0-9][0-9])'
const prefixPattern = '([1-9][0-9][0-9])'
const postfixPattern = '([0-9][0-9][0-9][0-9])'
const numsep = '(-)';
    
const phreg = new RegExp(
    '^' + areaCodePattern + numsep + prefixPattern + numsep + postfixPattern + '$',
    'g'
)
        
const Field = forwardRef((props,ref) => {
    const childFieldRef = useRef([]);
    const myRef = useRef(null);
    const fieldRef = useRef();
    const [value, setValue] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [validationMessage, setValidationMessage] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [fields, setFields] = useState([]);
    const [autoSelected, setAutoSelected] = useState(false);
    const { topicId, setTopicId, subject, setSubject, setMessageBody, messageContext,
        customFields, setCustomFields, updateCustomFields } = useMessageDraft();

    useEffect(() => {
        return () => {
            setCustomFields([]);
        }
    }, [])

    useEffect(() => {
        if(props.field.name == 'subject' && props.isReply && props.subject) {
            if (props.subject.match(/^Re:/gi)) {
                setSubject(props.subject);
                setValue(props.subject);
            } else {
                setSubject(`Re: ${props.subject}`);
                setValue(`Re: ${props.subject}`);
            }
        } else if(props.field.name == 'subject') {
            setValue(subject);
        }

    }, []);
    
    useEffect(() => {
        if(customFields && props.field.name){
            const val = customFields.find(v => v.name === props.field.name);
            if (!val?.value) return;
            
            if (props.field.type === "select") {
                populateSelectValue(val?.value)
            } else if(props.field.type === "radio") {
                onRadioChange(val.value);
            } else {
                setValue(val.value);
            }
        }
    }, [])

    useEffect(() => {
        const { defaultNotification} = checkNestedSelect(props.field)
        if (props.field.type == 'select' && defaultNotification) {
            setNotificationMessage(defaultNotification)
        }
    }, []);
    // auto select
    useEffect(() => {
        // if this is a select
        if (props.field.type === 'select') {
            const fieldName = props.field.name;
            // get the number of non default values
            const nonDefaultValues = props.field?.options?.filter(opt => opt.defaultValue !== "true");
            // if there is only 1 non-default value, automatically select it
            const shouldAutoSelect = !props.parent && nonDefaultValues?.length == 1 || (fieldName === 'topic' && topicId?.length > 0);
            if (shouldAutoSelect) {
                // get the option
                const option = nonDefaultValues[0];
                // determine the correct value for the option
                const value = valueForOpt(option);
                setValue(value);
                // add it to the custom fields
                const customField = {
                    name: fieldName,
                    label: props.field.label,
                    value: value,
                    text: option.text};
                setCustomFields([...customFields, customField]);
                setAutoSelected(true);
                if (option.fields) {
                    setFields(option.fields);
                }
                if (option.topicId) {
                    setTopicId(option.topicId);
                }
            }
        }
    }, [])

    useEffect(() => {
        if (messageContext && messageContext.draft) {
            const attributes = JSON.parse(messageContext.attributes);
            if (childFieldRef.current.length > 0) {
                childFieldRef.current.forEach(ref => {
                    const data = attributes.compose_data[ref.field.name];
                    if (data) {
                        ref.setFieldValue(data.value);
                    } else {
                        // do nothing
                        //console.error('not found ', ref.field.name);
                    }
                })
            } else if (props.field.type === 'select') {
                const data = attributes.compose_data[props.field.name];
                if (data) {
                    setFieldValue(data.value);
                }
            }
        }
    }, [childFieldRef.current.length])

    const valueForOpt = (opt) => {
        return opt.defaultValue == 'true'
            ? ''
            : opt.value
            ? opt.value
            : opt.name
            ? opt.name
            : opt.text
    }

    useImperativeHandle(ref,()=> ({
        validateField(fieldType){
            let fieldErrs = [];
            if (value === '') {
                const fieldErr = validateEmptyField(fieldType);
                if (fieldErr) {
                    fieldErrs.push(fieldErr);
                }
            } else {
                const fieldErr = validateNonEmptyField(fieldType);
                if (fieldErr) {
                    fieldErrs.push(fieldErr);
                }
            }
            if (fields.length > 0) {
                fields.forEach((field, idx) => {
                    const fieldRef = childFieldRef.current[idx];
                    const childFieldErr = fieldRef.validateField(field);
                    if (childFieldErr.length > 0) {
                        childFieldErr.forEach(cfe => {
                            cfe.ref = fieldRef;
                        });
                        fieldErrs = fieldErrs.concat(childFieldErr);
                    }
                })
            }
            return fieldErrs;
        },
        isValidField() {
            return !(errorMessage || validationMessage)
        },
        scrollIntoView() {
            myRef.current.scrollIntoView({ block: 'center', behaviour: 'smooth' });
        },
        setValue,
        field: props.field,
        setFieldValue,
        getChildren: () => {return childFieldRef;},
        isFocusable() {
            return fieldRef.current ? true : false;},
        focus() {
            if (fieldRef.current) {
                fieldRef.current.focus();
            }
        }
    }))

    const validateEmptyField = (field) => {
        let fieldErr = null;
        setValidationMessage('');
        if (props.field.required === "true") {
            if (field.type === 'select') {
                setErrorMessage(field.errorMessage);
                fieldErr = {id: field.name, message: field.errorMessage};
            } else if (field.type !== 'attachmentUploader') {
                setErrorMessage(field.errorMessage);
                fieldErr = {id: field.name, message: field.errorMessage};
            }
        }
        return fieldErr;
    }

    const updateFields = (fieldValue) => {
        if(fieldValue === ''){
            validateEmptyField(props.field.type);
        }else{
            validateNonEmptyField(props.field.type);
        }
        // if the existing value is blank
        let customField = null;
        if (typeof fieldValue === 'string') {
            customField = {
                name: props.field.name,
                label: props.field.label,
                value: fieldValue,
                text: fieldValue,
                filterTag: props.field?.filterTag};
        } else {
            customField = {
                name: props.field.name, 
                label: props.field.label, 
                value: valueForOpt(fieldValue), 
                text: fieldValue.text, 
                kvps: fieldValue?.kvps,
                filterTag: fieldValue?.filterTag};
            if (fieldValue.topicId) {
                setTopicId(fieldValue.topicId);
            }
            if (fieldValue.fields) {
                setFields(fieldValue.fields);
            }
        }
        const currentCustomField = customFields.find(cf => cf.name === customField.name);
        if (value === '' && !currentCustomField) {
            setCustomFields([...customFields, customField]);
        } else {
            updateCustomFields(customField);
        }
    }

    const validateNonEmptyField = (fieldType) =>{
        let fieldErr = null;
        if (fieldType === 'date') {
            fieldErr = validateDateField();
        } else if(fieldType === 'phone') {
            fieldErr = validatePhoneField();
        } else {
            setErrorMessage('');
        }
        return fieldErr;
    }

    const validateDateField = () =>{
        let fieldErr = null;
        setErrorMessage('');
        if (!value.match(reg)) { 
            setValidationMessage('Enter valid ' + props.field.label + ' using mm/dd/yyyy format');
            fieldErr = {message: 'Enter valid ' + props.field.label + ' using mm/dd/yyyy format', id: props.field.name};
        } else {
            if (new Date(value).getTime() <= new Date()) {	   
                setValidationMessage('');		
            } else {
                setValidationMessage('Enter valid ' + props.field.label + ' using mm/dd/yyyy format');
                fieldErr = {message: 'Enter valid ' + props.field.label + ' using mm/dd/yyyy format', id: props.field.name};
            }
        }
        return fieldErr;
    }

    const validatePhoneField = () =>{
        let fieldErr = null;
        setErrorMessage('');
        if (value.length > 0 && !value.match(phreg)) {
            const baseMessage = `Enter valid ${props.field.label}`
            const withHintMessage = props.field.hint ? `${baseMessage} using ${props.field.hint} format`: baseMessage;
            setValidationMessage(withHintMessage); 
            fieldErr = {message: baseMessage, id: props.field.name};
        } else {
            setValidationMessage('');						  
        }	
        return fieldErr;
    }
    
    const populateSelectValue = (val) => {
        setValue(val);
        const opt = props.field.options.find(opt => {
            return valueForOpt(opt) === val
        })
        setNotificationMessage(opt?.notification);
        updateFields(opt);
    }

    const onOptionChange = (e) =>{
        populateSelectValue(e.target.value);
    }

    const onRadioChange = (optionValue) =>{
        setValue(optionValue);
        const opt = props.field.options.find(opt =>{
            return opt.text === optionValue
        })
        setNotificationMessage(opt?.notification);
        if (opt.fields) {
            setFields(opt.fields);
        } else {
            setFields([]);  
        }
        updateFields(optionValue);
    }

    const onDateChange = (e) =>{
        const newValue = formatDateValue(e.target.value);
        setValue(newValue);
    }
    
    const onBlurDateHandler = (e) => {
        const newValue = formatDateValue(e.target.value);
        updateFields(newValue);
        setValidationMessage('');
        validateDateField();
    }
    
    const formatDateValue = newValue => {
        let numberOnlyValue = newValue.replace(new RegExp(numbersOnly, 'gm'), '')
        let numbersAndSlashesValue = newValue.replace(new RegExp(numbersAndSlashes, 'gm'), '')
        if (numberOnlyValue.length === 8 && numbersAndSlashesValue.length === 8) {
            return (
                numberOnlyValue.slice(0, 2) +
                '/' +
                numberOnlyValue.slice(2, 4) +
                '/' +
                numberOnlyValue.slice(4)
            )
        }
        return numbersAndSlashesValue.length > 10
            ? numbersAndSlashesValue.slice(0, 10)
            : numbersAndSlashesValue
    }
            
    const onPhoneChange = (e) => {
        const newValue = formatValue(e.target.value);
        setValue(newValue);
    }
    
    const onBlurHander = (e) => {
        // this currently does nothing, leaving it here for future use
        const field = props.field;
        if (field.required === "true") {
            //validateNonEmptyField(field.type);
        } 
    }

    const onPhoneBlurHandler = () => {
        updateFields(value);
        setValidationMessage('');
        validatePhoneField()
    }
    
    const formatValue = newValue => {
        let numberOnlyValue = newValue.replace(new RegExp(numbersOnly, 'gm'), '')
        let numbersAndDashesValue = newValue.replace(new RegExp(numbersAndDashes, 'gm'), '')
        if (numberOnlyValue.length === 10 && newValue.length === 10) {
            return (
                numberOnlyValue.slice(0, 3) +
                '-' +
                numberOnlyValue.slice(3, 6) +
                '-' +
                numberOnlyValue.slice(6)
            )
        }
        return numbersAndDashesValue.length > 12
        ? numbersAndDashesValue.slice(0, 12)
        : numbersAndDashesValue
    }
    
    const setFieldValue = (newValue) => {
        setValue(newValue);
        if (props.field.name === 'subject') {
            // do nothing
        } else if (props.field.name === 'body') {
            onEditorChange(newValue);
        } else if (props.field.type === 'select') {
            populateSelectValue(newValue);
        }
    }

    const onEditorChange = (e) => {
        if (e) {
            setErrorMessage('');
            setValidationMessage('');
        }
        setValue(e);
        setMessageBody(e);
    }
    
    const onInputChange = (e) => {
        setValue(e.target.value);
        if (props.field.name == 'subject') {
            if (e.target.value) {
                setErrorMessage('');
                setValidationMessage('');
            }
            setSubject(e.target.value);
        } else {
            updateFields(e.target.value);
        }
    }
    
    const getFieldId = () => {
        const {field} = props;
        const fieldId = field.name || field.id || field.label;
        return fieldId;
    }
    
    const checkNestedSelect = (field) => {
        return {
            field
        };
        /*
        const nonDefaultValues = field?.options?.filter(opt => opt.defaultValue !== "true");
        const hasNestedFields = field?.options?.find(v => v.fields?.length);
        if (hasNestedFields && nonDefaultValues?.length > 1) {
            return {
                field
            };
        }
        const defaultField = hasNestedFields?.fields?.[0]
        const baseField = defaultField || field;
        
        return {
            field: baseField,
            defaultNotification: defaultField ? hasNestedFields.notification: ''
        }*/
    }

    const renderField = (field) => {
        let component = null, tabIndex = 0;
        const hasError = errorMessage || validationMessage;

        switch (field.type) {
            case 'select': 
                const {field: baseField} = checkNestedSelect(field);
                
                component = <Select 
                    field={baseField}
                    ref={fieldRef}
                    id={getFieldId()}
                    hasError={hasError}
                    errorMessage={errorMessage}
                    value={value}
                    onChangeHandler={e => onOptionChange(e)}
                    onBlurHandler={onBlurHander}
                    tabIndex={tabIndex} />;
                break;
            case 'input': 
                component = <Input 
                    field={field}
                    ref={fieldRef} 
                    id={getFieldId()} 
                    hasError={hasError}
                    errorMessage={errorMessage} 
                    value={value}
                    onChangeHandler={e => onInputChange(e)}
                    onBlurHandler={onBlurHander}
                    tabIndex={tabIndex} />; 
                break;
            case 'attachmentUploader':
                component = <Attachment
                    field={field} 
                    id={getFieldId()} 
                    hasError={hasError}
                    errorMessage={errorMessage} 
                    value={value}
                    onChangeHandler={e => setValue(e.target.value)}
                    onBlurHandler={() => {}}
                    tabIndex={tabIndex} />;
                break;
            case 'ckEditor': 
                component = <OptumEditor 
                    field={field} 
                    id={getFieldId()} 
                    hasError={hasError}
                    errorMessage={errorMessage} 
                    value={value}
                    onChangeHandler={e => onEditorChange(e)}
                    onBlurHandler={onBlurHander}
                    tabIndex={tabIndex} />;
                break;
            case 'phone': 
                component = <Input 
                    field={field} 
                    ref={fieldRef} 
                    id={getFieldId()} 
                    hasError={hasError}
                    errorMessage={errorMessage} 
                    value={value}
                    onChangeHandler={e => onPhoneChange(e)}
                    onBlurHandler={onPhoneBlurHandler}
                    tabIndex={tabIndex} />;
                break;
            case 'date':
                component = <DateField 
                    field={field} 
                    ref={fieldRef} 
                    id={getFieldId()} 
                    hasError={hasError}
                    errorMessage={errorMessage} 
                    value={value}
                    onChangeHandler={e => onDateChange(e)}
                    onBlurHandler={onBlurDateHandler}
                    tabIndex={tabIndex} />;
                break;
            case 'radio':
                component = <Radio
                    field={field} 
                    id={getFieldId()} 
                    hasError={hasError}
                    errorMessage={errorMessage} 
                    value={value}
                    onChangeHandler={(optionValue) => onRadioChange(optionValue)}
                    onBlurHandler={() => {}}
                    tabIndex={tabIndex} />;
                break;
            default: break;
        }
        return component;
    }

    return (
        <div className={'oms--field' + (props.parent ? ' oms--child' : '')} ref={myRef}>
            {notificationMessage &&
                (!notificationMessage.pos || notificationMessage.pos === 'top') && (
                    <FieldNotification notification={notificationMessage} />
                )
            }
            {!autoSelected &&
                <>
                <label htmlFor={getFieldId()}>
                    <span>
                        {checkNestedSelect(props.field)?.field?.label}
                    </span>
                    {props.field.required && <span aria-hidden='true' className='oms--red-astrik'>*</span>}
                    {props.field.tooltip && (
                        <FieldTip
                            tooltip={props.field.tooltip}
                            id={getFieldId()}
                            tabIndex={-1}
                        />
                    )}
                </label>
                {(errorMessage || validationMessage) && (
                    <div className={`oms--field-error-message  oms--type-${props.field.type}`} role='alert' id={getFieldId() + '_error'}>
                        <span className='oms--error-icon'>
                            <span className='sm-icon icon--alert_filled' />
                            <ScreenReaderOnly message={errorMessage || validationMessage} />
                        </span>
                        <span>{errorMessage || validationMessage}</span>
                    </div>
                )}
                {renderField(props.field)}
                {props.field.hint && (
                    <div id={'hint_message_' + getFieldId()} className='oms--hint-message'>
                        {props.field.hint}
                    </div>
                )}
                {notificationMessage && notificationMessage.pos === 'bottom' && (
                    <FieldNotification notification={notificationMessage} />
                )}
                </>
            }
            {fields.length > 0 && fields.map((field, idx) => 
                <Field 
                    key={idx} 
                    field={field}
                    parent={true} 
                    
                    ref={(element) => {childFieldRef.current[idx] = element}}
                />
            )}
        </div>
    )
})
                                                            
export default Field;
                                                            