import React, { useState } from 'react';
import { useView } from './view';
import { useErrors } from './errors';
import { useConfig } from './config';
import { useLang } from './lang';
import { useAnalytics } from './analytics';
import axios from '@util/axios_config';

const MessageDraftContext = React.createContext(null);

function MessageDraftProvider({children}) {
    const { token, messageMetadata, topicId:configTopicId } = useConfig();
    const { setManager, setNotificationMessage, setThread, folder, setFolder } = useView();
    const errors = useErrors();
    const { dict } = useLang();
    const { publish } = useAnalytics();
    const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [currentSenderMailbox, setCurrentSenderMailbox] = useState(null);
    const [attachmentIds,setAttachmentIds] = useState([]);
    const [messageBody,setMessageBody] = useState('');
    const [customFields,setCustomFields] = useState([]);
    const [topicId,setTopicId] = useState('');
    const [subject,setSubject] = useState('');
    const [messageContext, setMessageContext] = useState({});

    const cleanHiddenCharacters = (text) => {
        // remove new carriage return, newline, and tab characters
        // we do this here because the smapi cannot handle these characters
        const cleanCarriageReturns = text.replace(/\r\n/g, '');
        const cleanNewlines = cleanCarriageReturns.replace(/\n/g, '');
        const cleanTabs = cleanNewlines.replace(/\t/g, '');
        return cleanTabs;
    }

    const sendNewMessage = async (draft = false) => {
        if (!draft) {
            setIsSending(true);
        } else {
            setIsSaving(true);
        }
        try {
            let messageData = {
              topicId: configTopicId || topicId,
              subject: encodeURIComponent(cleanHiddenCharacters(subject)),
              body: encodeURIComponent(cleanHiddenCharacters(messageBody)),
              attachmentIds,
              customFields,
              draft,
              kvps: []
          }
          // add the message metadata to the custom fields
          if (messageMetadata && messageMetadata.length > 0) {
            try {
              // parse the message metadata
              const metadata = JSON.parse(messageMetadata);
              metadata.forEach(md => {
                if (md.key === 'topicId') {
                    messageData.topicId = md.value;
                } else {
                    const customMetadataField = {
                        key: md.key,
                        value: md.value
                    }
                    messageData.kvps.push(customMetadataField)
                }
              })
            } catch (err) {
              console.error('Invalid messageMetadata/kvps');
            }
          }
          // add kvps from custom fields
          customFields.forEach(cf => {
            if (cf.kvps && cf.kvps.length > 0) {
              cf.kvps.forEach(kvp => {
                messageData.kvps.push(kvp)
              })
            }
          })
          // publish analytics
          publish(
            { resource: 'send' },
            { messageDraft: messageData }
          )
          let config = {};
          if (token) {
            config.headers = {Authorization: `Bearer ${token}`};
          }
          if (messageContext.draft) {
            messageData.thread_id = messageContext.thread_id;
            messageData.attributes = messageContext.attributes;
          }
          const url = messageContext.draft ? 'facade/messages/' + messageContext.message_id : 'facade/messages';
          const response = await axios.post(url, messageData, config);
          if (response.status === 200) {
              setManager('browse');
              setThread(null);
              setNotificationMessage(draft ? dict.successSave.message : dict.successSend.message);
              setAttachmentIds([]);
              setMessageContext({});
              if (folder === 'drafts') {
                  setFolder('inbox');
              }
          }
        } catch (error) {
            let message = '';
            if (error.response) {
                message += error.response.data?.errors?.join();
                console.log(error.response.status + ': ' + message);
            } else {
                message = 'unable to send message.';
            }
            errors.add('messages:failure', message, error, 'Message send failure')
        } finally {
            if (!draft) {
                setIsSending(false);
            } else {
                setIsSaving(false);
            }
        }
    }

    const sendReplyMessage = async (toRecipients, draft=false) => {
        if (!draft) {
            setIsSending(true);
        } else {
            setIsSaving(true);
        }
        try {
            const messageData = {
                thread_id: messageContext.thread_id,
                sender: { mid: currentSenderMailbox.mid },
                recipients: { recipient: toRecipients },
                subject: encodeURIComponent(cleanHiddenCharacters(subject)),
                body: encodeURIComponent(cleanHiddenCharacters(messageBody)),
                attachments: {
                    attachment: attachmentIds.map(id => {return { attachment_id: id }})
                },
                attributes: messageContext.attributes,
                draft,
                tags: { tag: (messageContext.tags && messageContext.tags.tag ? messageContext.tags.tag : messageContext.tags ) }
            }
            let config = {
                headers: {'Content-Type': 'application/json'}
            }
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            // publish analytics
            publish(
                { resource: 'send' },
                { messageDraft: messageData, isReply: true }
            )
            // send the message
            const url = messageContext.draft && draft ? 'messages/' + messageContext.message_id : 'messages';
            const response = await axios.post(url, messageData, config);
            if (response.status === 200) {
                setManager('browse');
                setThread(null);
                setNotificationMessage(draft ? dict.successSave.message : dict.successSend.message);
                setAttachmentIds([]);
                if (folder === 'drafts') {
                    setFolder('inbox');
                }
            }
        } catch (e) {
            console.error(`Error while replying message ${e}`);
        } finally {
            if (!draft) {
                setIsSending(false);
            } else {
                setIsSaving(false);
            }
        }
    }

    const deleteDraft = async (messageId, callback) => {
        try {
            const messageData = {}
            let config = {
                headers: {'Content-Type': 'application/json'}
            }
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            const path = 'messages/' + messageId + '/delete'
            const response = await axios.post(path , messageData, config);
            if (response.status === 200) {
                setManager('browse');
                setThread(null);
                setNotificationMessage(dict.successDelete.message);
                setAttachmentIds([]);
            }
        } catch (e) {
            console.error(`Error while replying message ${e}`);
        } finally {
            setIsSending(false);
            callback();
        }
    }

    const provider = {
        isUploadingAttachments, setIsUploadingAttachments,
        isSending, isSaving,
        currentSenderMailbox, setCurrentSenderMailbox,
        attachmentIds, setAttachmentIds,
        messageBody, setMessageBody,
        messageContext, setMessageContext,
        customFields, setCustomFields,
        updateCustomFields : (field) => {
            if (customFields.length > 0) {
                updateExistingField(field);
            } else {
                setCustomFields([...customFields,field]);
            }
        },
        topicId, setTopicId,
        subject, setSubject,
        sendNewMessage, sendReplyMessage, deleteDraft
    }
        
    function updateExistingField(field){
        let newFieldArray = customFields.filter((cusField,idx) =>{
            return cusField.name !== field.name
        })
        if (field.value !== '') {
            newFieldArray.push(field);
        }
        setCustomFields(newFieldArray);
    }

    return <MessageDraftContext.Provider value={provider}>{children}</MessageDraftContext.Provider>
}
    
function useMessageDraft() {
    const context = React.useContext(MessageDraftContext);
    if (!context) {
        throw new Error('useMessageDraft must be called within a MessageDraftContext provider');
    }
    return context;
}

export {MessageDraftProvider, useMessageDraft}
    